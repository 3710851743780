import React, { FC, useMemo } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import sop from '../../files/NY-StandardOperatingProcedures.pdf'
import { FooterWrapper, FooterItems, FooterLinks, FooterText } from "./index.styled";
const pagesData = [
  {
    name: "Availability",
    link: "/availability",
  },
  {
    name: "Contact",
    link: "/contact",
  },
  {
    name: "Legal",
    link: "/legal",
  },
];

const Footer: FC = () => {
  const data = useStaticQuery(query);
  const { Footer: items } = data.strapiTheBuildingPage;
  const currentYear = useMemo(() => new Date().getFullYear(), [])

  return (
    <FooterWrapper>
      <FooterLinks>
        {pagesData.map((el, key) => (
          <Link to={el.link} key={key}>
            {el.name}
          </Link>
        ))}
        <a
          href="fairhousingnotice.pdf"
          target="_blank"
          className={"menu-link"}
        >
          Fair Housing Notice
        </a>
        <a
          href={sop}
          target="_blank"
          className={"menu-link"}
        >
          Standard Operating Procedures
        </a>
      </FooterLinks>
      <FooterItems>
        <p>{items.AddresLine1}</p>
        <p>{items.AddresLine2}</p>
      </FooterItems>
      <FooterText>COPYRIGHT &copy; {currentYear} 200 East 83rd Street. ALL RIGHTS RESERVED.</FooterText>
    </FooterWrapper>
  );
};

const query = graphql`
  {
    strapiTheBuildingPage {
      id
      Footer {
        AddresLine2
        AddresLine1
      }
    }
  }
`;

export default Footer;
